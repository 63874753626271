<template>
  <div class="license" @click="$router.push('/payment')">
    <span v-if="project.project_license_type && project.is_expired" style="color: brown">*</span>
    <span
      class="license-expired"
      :style="{'bottom': `${bottomPush}px`, 'right': `${rightPush}px`}"
      v-if="project.project_license_type && project.is_expired"
    >
      Your license is expired!
    </span>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
    },
    bottomPush: {
      type: Number
    },
    rightPush: {
      type: Number
    }
  }
}
</script>

<style lang="scss" scoped>
.license {
  &:hover {
      .license-expired {
        opacity: 1;
        transition: 0.3s;
      }
    }
  .license-expired {
    position: absolute;
    color: #d02d2d;
    opacity: 0;
  }
}
</style>