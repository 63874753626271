<template>
  <div>
    <h4 class="mb-2">Update project</h4>
    <b-form-group
      label="Project UUID"
      label-for="form-name"
      label-cols-lg="2"
    >
      <div v-if="projectData && projectData.uuid">
        {{ projectData.uuid }}
      </div>
    </b-form-group>
    <b-form class="mb-2">
      <b-row>
        <!-- Field: Project name -->
        <b-col cols="12" md="4">
          <b-form-group label="Project Name" label-for="project_name">
            <b-form-input id="project_name" v-model="projectData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Comment -->
        <b-col cols="12" md="4">
          <b-form-group label="Comment" label-for="comment">
            <b-form-textarea
              id="textarea"
              v-model="projectData.comment"
              placeholder="Comment"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Project name -->
        <b-col cols="12" md="4">
          <b-form-group label="Collect Analytics" label-cols-lg="4" label-for="collect_analytics">
            <b-form-checkbox
              v-model="projectData.collect_analytics"
              inline
              value="true"
              id="collect_analytics"
              style="margin-top:5px"
            ></b-form-checkbox>
          </b-form-group>
          <b-form-group label="Is Publish" label-for="is_publish" label-cols-lg="4">
              <b-form-checkbox
                v-model="projectData.is_publish"
                id="is_publish"
              >
              </b-form-checkbox>
          </b-form-group>
            <div class="d-flex flex-column bd-highlight">
              <p>Preview image in Portfolio:</p>
              <b-span v-if="!projectData.portfolio_img_s3_path" class="text-warning mb-2">
                You don't have any image yet
              </b-span>
              <b-img
                v-if="projectData.portfolio_img_s3_path"
                :src="projectData.portfolio_img_s3_path"
                style="object-fit: cover; margin: 10px; width: 50%; float: left"
              />
            </div>
            <div style="display: flex;">
                <b-form-file
                  accept=".jpg,.png,.jpeg"
                  ref="file-input"
                  v-model="portfolioImg"
                  class="mb-2"
                />
              </div>
          <b-form-group
            label="Chose Project license:"
          >
            <div style="display: flex">
              <b-form-radio-group
                v-model="projectData.project_license_type"
                class="mb-1"
                disabled-field="notEnabled"
              >
                <b-form-radio value="1">
                  <div style="display: flex;">
                    Basic <LicenseExpired v-if="projectData.project_license_type == 1" :project="projectData" :bottomPush="-23" :rightPush="-70"/>
                  </div>
                </b-form-radio>
                <b-form-radio value="2">
                  <div style="display: flex;">
                    Plus <LicenseExpired v-if="projectData.project_license_type == 2" :project="projectData" :bottomPush="-23" :rightPush="-70"/>
                  </div>
                </b-form-radio>
                <b-form-radio value="3">
                  <div style="display: flex;">
                    Pro <LicenseExpired v-if="projectData.project_license_type == 3" :project="projectData" :bottomPush="-23" :rightPush="-70"/>
                  </div>
                </b-form-radio>
              </b-form-radio-group>
              <a href="/pricing" target="_blank" title="Click to see differents">
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="InfoIcon"
                  size="24"
                />
              </a>
            </div>
          </b-form-group>
          <div class="mb-2">
              <span class="mr-1">Left {{ getChosenLicenseType }} licenses:</span>
              <span :class="{'not-enough-license': getCountChosenLicenseLeft < 1}">
                {{ getCountChosenLicenseLeft }}
              </span>
            </div>
        </b-col>
      </b-row>
    </b-form>
    <!-- {{projectData}} -->
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="onSubmit"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :disabled="isSavebuttonDisabled || busyButton"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      @click="resetProjectData"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :disabled="busyButton"
    >
      Reset
    </b-button>
    <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text class="font-weight-bolder">
            Are you sure?
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              Cancel
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="sendRequestToUpdateProject"
            >
              OK
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import LicenseExpired from "@/views/components/UI/LicenseExpired.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    LicenseExpired,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      busy: false,
      busyButton: false,
      optionsProjectLicenseType: [
        { item: 1, name: "Basic" },
        { item: 2, name: "Plus" },
        { item: 3, name: "Pro" },
      ],
      portfolioImg: null,
    }
  },
  computed: {
    isSavebuttonDisabled() {
      return !this.projectData.name
    },
    getLeftUserLicenses() {
      return this.$store.getters["Project/getLeftLicenses"]
    },
    getChosenLicenseType() {
      let licenseTypes = {1: "Basic", 2: "Plus", 3: "Pro"}
      return licenseTypes[this.projectData.project_license_type]
    },
    getCountChosenLicenseLeft() {
      let licenseTypes = {1: "basic_count", 2: "plus_count", 3: "pro_count"}
      return this.getLeftUserLicenses[licenseTypes[this.projectData.project_license_type]]
    },
  },
  methods: {
    onSubmit() {
      this.busy = true
      this.busyButton = true
    },
    onCancel() {
      this.busy = false;
      this.busyButton = false
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus();
    },
    sendRequestToUpdateProject() {
      const collect_analytics =
        this.projectData.collect_analytics == "true" ||
        this.projectData.collect_analytics == true
          ? true
          : false;
      if (this.portfolioImg) {
        this.$store.dispatch("Project/updatePortfolioImage", {projectID: this.projectData.id, image: this.portfolioImg })
      }
      store
        .dispatch("Project/updateProject", {
          projectId: store.state.Project.project.id,
          name: this.projectData.name,
          comment: this.projectData.comment,
          collect_analytics: collect_analytics,
          project_license_type: this.projectData.project_license_type,
          is_publish: this.projectData.is_publish,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "You have successfully update this project",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: err.response.data?.detail
                ? err.response.data?.detail
                : "Please try again",
            },
          });
        })
        .finally(() => {
          this.onCancel()
        });
    },
    resetProjectData() {
      this.projectData.name = "";
      this.projectData.comment = "";
    },
  },
  mounted() {
    this.$store.dispatch("Project/getLeftLicenses");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.license {
  &.dark-color {
    color: #b4b7bd;
  }
  &:hover {
      .license-expired {
        opacity: 1;
        transition: 0.3s;
      }
    }
  .license-expired {
    position: absolute;
    margin: 0 0 38px -50px;
    color: #d02d2d;
    opacity: 0;
  }
}
</style>
